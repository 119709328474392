import React, {useCallback, useState} from 'react'
import styled from 'styled-components'
import * as emailjs from 'emailjs-com'

import SocialMedias, {SocialMediasContainer} from 'components/contacts/SocialMedias'
import Tel from 'components/contacts/Tel'
import Email from 'components/contacts/Email'
import Location from 'components/contacts/Location'
import Layout from 'components/layout/Layout'
import Header from 'components/header/Header'
import Main from 'components/layout/Main'

const SERVICE_ID = 'feedback_tsaryk_com'
const TEMPLATE_ID = 'yana_tsaryk_com'
emailjs.init('user_C3T41f8F0pv1ekwT39nYw')

const FormWrapper = styled.div`
  background-color: #1b1b1b;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 1em;
  width: 500px;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 0 2em;

  input,
  textarea {
    background-color: #252525;
    border: 1px #666 solid;
    color: #fff;
    margin: 0 0 0.5em;
    padding: 0.5em;
  }

  input[type='submit'] {
    align-self: center;
    cursor: pointer;
    width: 50%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`

const ResultMessage = styled.span`
  display: inline-block;
  min-height: 45px;
  text-align: center;
`

const LoadingMessage = styled(ResultMessage)`
  animation: 1s linear 0s infinite blinking;
  color: #666;

  @keyframes blinking {
    from {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`
const SuccessMessage = styled(ResultMessage)`
  color: #9f9;
`
const ErrorMessage = styled(ResultMessage)`
  color: #f99;
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin: 0 0 0.5em;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  & > ${SocialMediasContainer} {
    align-self: center;
  }
`

enum Result {
  NONE,
  LOADING,
  SUCCESS,
  ERROR,
}

interface TargetForm extends EventTarget {
  name: HTMLInputElement
  tel: HTMLInputElement
  email: HTMLInputElement
  message: HTMLInputElement
}

const ContactPage: React.FunctionComponent = () => {
  const [result, setResult] = useState(Result.NONE)

  const onSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (result !== Result.NONE) {
      return
    }

    setResult(Result.LOADING)
    const params = {
      name: (event.target as TargetForm).name.value,
      tel: (event.target as TargetForm).tel.value,
      email: (event.target as TargetForm).email.value,
      message: (event.target as TargetForm).message.value,
    }
    try {
      await emailjs.send(SERVICE_ID, TEMPLATE_ID, params)
      setResult(Result.SUCCESS)
    } catch {
      setResult(Result.ERROR)
    }
  }, [])

  return (
    <Layout>
      <Header text="Контакты" />
      <Main>
        <p>
          Вы можете связаться со мной любым удобным для вас способом. Я доступна 24/7 по электронной
          почте или телефону. Вы также можете использовать форму обратной связи, расположенную на
          этой странице, чтобы задать вопрос о моих услугах и проектах, над которыми я работаю.
        </p>
        <FormWrapper>
          <Form onSubmit={onSubmit}>
            <input
              required
              disabled={result !== Result.NONE}
              type="text"
              name="name"
              placeholder="Ваше имя"
            ></input>
            <input
              required
              disabled={result !== Result.NONE}
              type="text"
              name="tel"
              placeholder="Номер телефона"
            ></input>
            <input
              required
              disabled={result !== Result.NONE}
              type="text"
              name="email"
              placeholder="Почта"
            ></input>
            <textarea
              required
              disabled={result !== Result.NONE}
              name="message"
              placeholder="Сообщение"
              rows={4}
            ></textarea>
            {result === Result.NONE && <input type="submit" value="Отправить"></input>}
            {result === Result.LOADING && <LoadingMessage>Отправка...</LoadingMessage>}
            {result === Result.SUCCESS && (
              <SuccessMessage>
                Спасибо за сообщение!
                <br />В скором времени я с вами свяжусь.
              </SuccessMessage>
            )}
            {result === Result.ERROR && (
              <ErrorMessage>
                Произошла ошибка при отправке.
                <br />
                Пожалуйста, свяжитесь со мной по телефону или имейлу.
              </ErrorMessage>
            )}
          </Form>
          <ButtonsWrapper>
            <Location />
            <Tel />
            <Email />
            <SocialMedias />
          </ButtonsWrapper>
        </FormWrapper>
      </Main>
    </Layout>
  )
}

export default ContactPage
